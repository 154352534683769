import { mapGetters, mapActions } from "vuex"

export default {
    data() {
        return {
            prevRoute: null,
            sfEmail: "",
            sfPassword: "",
            sfType: "",
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    computed: {
        ...mapGetters(["getOrgId", "getProspectId", "getAppFlow", "getQuestions", "getEditModeFlag", 'getIsCoPilot', 'getCoPilotEmail', 'getCloneAppFlag', 'getCreateAppFlag']),
    },
    beforeMount() {
        if (this.getIsCoPilot) this.fetchIsCoPilot(false);
        if (this.getCoPilotEmail !== "") this.fetchCoPilotEmail("");
    },
    mounted() {
        this.fetchLoaderStatus(true);

        this.sfGetQueryString();

        if (this.getIsCoPilot && this.prevRoute.name !== 'SwitchApplication') {
            if (this.getCreateAppFlag) this.fetchCreateAppFlag(false);
            if (this.getCloneAppFlag) this.fetchCloneAppFlag(false);
        }

        this.$nextTick(() => {
            if (Object.keys(this.$route.query).length && !this.getCloneAppFlag && !this.getCreateAppFlag) this.sfSignIn(); // Only used for SalesForce UI iFrame
        });
    },
    methods: {
        ...mapActions([
            "fetchProspectEmail",
            "fetchUserId",
            "fetchUser",
            "fetchIsAuthenticated",
            "fetchActiveQuestion",
            "fetchActiveSection",
            "fetchApplications",
            "fetchQuestions",
            "fetchSubQuestions",
            "fetchSections",
            "fetchLoaderStatus",
            "fetchIsCoPilot",
            "fetchCoPilotEmail",
            "fetchCreateAppFlag",
            "fetchCloneAppFlag",
            "updateQueObjectWithAnswer",
        ]),

        sfGetQueryString() {
            if (Object.keys(this.$route.query).length) {
                this.fetchIsCoPilot(true);
                this.fetchCoPilotEmail(this.$route.query.email);

                this.sfEmail = this.$route.query.email;
                this.sfPassword = this.$route.query.password;

                if (this.$route.query.type && this.$route.query.type !== 'null')
                    this.sfType = this.$route.query.type;
                else
                    this.sfType = 'Buying';


                this.fetchAppFlow(this.sfType);
            }
        },

        async sfSignIn() {
            let payLoad = {
                authenticated: true,
                flowName: this.getAppFlow,
                prospectEmail: this.sfEmail,
                prospectPassword: "", //will be always empty string while SF-CoPilot login
                prospectFirstName: "", //will be always empty string while SF-CoPilot login
                prospectLastName: "", //will be always empty string while SF-CoPilot login
                orgId: this.getOrgId,
                userId: this.getProspectId,
            };

            await this.$http
                .post("/account/Prospect/Auth", payLoad)
                .then(async (response) => {
                    let resp = response.data;

                    if (resp.response === "Success") {
                        this.fetchIsAuthenticated(true);
                        this.fetchProspectEmail(payLoad.prospectEmail);
                        this.fetchApplications(resp.applications);

                        this.sfEmail = "";
                        this.sfPassword = "";
                        this.sfType = "";

                        await this.sfGetQuestionnaire();
                        await this.sfContinueWithApp();
                    }

                    if (resp.response === "Error") this.err(resp.message);
                })
                .catch((error) => { this.err(error) });

            this.fetchLoaderStatus(false);
        },

        async sfGetQuestionnaire() {
            await this.$http
                .get(
                    "/question/GetProspectQuestions/" +
                    this.getAppFlow +
                    "/" +
                    this.getProspectId +
                    "?orgId=" +
                    this.getOrgId
                )
                .then((response) => {
                    let resp = response.data;

                    if (resp.response === "Success") {
                        this.fetchSections(resp.sidebarSections);
                        this.fetchQuestions(resp.appQuestions);
                        this.fetchSubQuestions(resp.subQuestions);
                    }

                    if (resp.response === "Error") console.log(resp.message);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async sfContinueWithApp() {
            await this.$http
                .get(
                    "/account/getSingleQuestionAnswer?prospectId=" +
                    this.getProspectId +
                    "&orgId=" +
                    this.getOrgId +
                    "&flowName=" +
                    this.getAppFlow
                )
                .then(async (response) => {
                    let resp = response.data;

                    if (resp.response === "Success") {
                        this.fetchUserId(resp.prospectId);
                        this.fetchUser(resp.prospectdetails);
                        this.updateQueObjectWithAnswer(resp.appQuestions);

                        // Redirect the user where they left off
                        await this.redirectUserWhereLeftOff(resp.prospectdetails.lastAnsweredQuestionId);
                    }

                    if (resp.response === "Error") this.err(resp.message);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async redirectUserWhereLeftOff(data) {
            let theFields = [];
            let lastAttendedQue = null;
            let lastQueId = '';

            if (data) {
                lastQueId = data.split("-");

                lastAttendedQue = this.getQuestions.find(
                    (k) => k.questionId === lastQueId[0]
                );
            }

            if (lastAttendedQue) {
                let lastQueIndex;

                // Check if Co-Applicant is added
                let isCoApplicantAdded = this.getQuestions.some(
                    (x) =>
                        (x.questionFieldMapping === "is_joint_applicant_added__c" || x.questionFieldMapping === "candidoem__is_joint_applicant_added__c") &&
                        x.questionAnswer === "Yes"
                );

                if (isCoApplicantAdded) {
                    lastQueIndex = this.getQuestions.at(-1).questionIndex;
                }

                if (!isCoApplicantAdded) {
                    let theQues = this.getQuestions.filter(
                        (j) => !j.dependentQuestionId && !j.dependentQuestionAnswer
                    );

                    lastQueIndex = theQues.at(-1).questionIndex;

                    if (lastQueId[0] === theQues.at(-1).questionId) {
                        lastAttendedQue = theQues.at(-1);
                    }
                }

                // If last question is answered then redirect user to Submit Application page
                // else redirect user to specific question
                if (lastAttendedQue.questionIndex === lastQueIndex) {
                    await this.$router.push("/submit-application");
                } else {
                    this.getQuestions.forEach((v) => {
                        if (
                            v.questionIndex > lastAttendedQue.questionIndex &&
                            v.questionType === "Checkbox"
                        ) {
                            v.questionAnswer = null;
                            v.coQuestionAnswer = null;
                        }
                    });

                    if (lastAttendedQue.groupName) {
                        for (let i = lastAttendedQue.questionIndex - 1; i > 0; i--) {
                            if (
                                this.getQuestions[i].groupName === null ||
                                this.getQuestions[i].groupName !== lastAttendedQue.groupName
                            ) {
                                break;
                            }
                            theFields.push(this.getQuestions[i]);
                        }

                        this.fetchActiveQuestion(theFields.at(-1).questionIndex);
                    }

                    if (!lastAttendedQue.groupName)
                        this.fetchActiveQuestion(lastAttendedQue.questionIndex);

                    this.fetchActiveSection(lastAttendedQue.sectionName);
                    await this.$router.push("/questionnaire");
                }
            }

            if (!lastAttendedQue) {
                this.getQuestions.forEach((v) => {
                    if (v.questionType === "Checkbox" && v.needsAuthentication) {
                        v.questionAnswer = null;
                        v.coQuestionAnswer = null;
                    }
                });

                this.fetchActiveQuestion(1);
                this.fetchActiveSection(this.getQuestions[0].sectionName);

                await this.$router.push("/questionnaire");
            }

            this.fetchLoaderStatus(false);
        },
    }
}